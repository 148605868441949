import React from "react";

function PrivacyPolicy() {
  return (
    <div className="pp-container">
      <h2>【motoblog】 プライバシーポリシー　/　privacy policy</h2>

      <h4>
        【motoblog】 及び 【Book
        eternalアプリ】(以下「当ブログ」)のプライバシーポリシー・免責事項を次の通り記載します。
      </h4>

      <h3>個人情報の利用目的について</h3>

      <p>
        当ブログでは、お問い合わせやコメント投稿の際に氏名・メールアドレス等の個人情報を入力いただく場合があります。
        取得した個人情報は、必要な連絡のみに利用させていただくもので、これらの目的以外では利用いたしません。
      </p>

      <h3>個人情報の第三者開示について</h3>

      <p>
        取得した個人情報は適切に管理し、以下に該当する場合を除いて第三者に開示することはありません。
        ・本人の同意が得られた場合 ・法令により開示が求められた場合
      </p>

      <h3>Cookieの使用について</h3>

      <p>
        当ブログでは、広告配信やアクセス解析のためにCookieを使用しています
        Cookieによりブラウザを識別していますが、特定の個人の識別はできない状態で匿名性が保たれています。
        Cookieの使用を望まない場合、ブラウザからCookieを無効に設定できます。
      </p>

      <h3>広告配信サービスについて</h3>

      <p>
        当ブログでは、第三者配信の広告サービスを利用して広告を掲載しています。
        第三者配信事業者は、ユーザーの興味に応じたパーソナライズ広告を表示するためにCookieを使用しています。
        パーソナライズ広告は、
        <a href="https://adssettings.google.com/authenticated" target="_blank">
          広告設定
        </a>
        で無効にできます。また、
        <a href="https://optout.aboutads.info/?c=2&lang=EN" target="_blank">
          [www.aboutads.info](http://www.aboutads.info/)
        </a>
        で第三者配信事業者のCookieを無効にすることができます。
        Amazonのアソシエイトとして、[ブログ名を記載]は適格販売により収入を得ています。
      </p>

      <h3>アクセス解析ツールについて</h3>

      <p>
        当ブログでは、Googleアナリティクスによりアクセス情報を解析しています。
        アクセス情報の解析にはCookieを使用しています。また、アクセス情報の収集はCookieを無効にすることで拒否できます。
        Google社のデータ収集・処理の仕組みについては、
        <a
          href="https://policies.google.com/technologies/partner-sites?hl=ja"
          target="_blank"
        >
          こちら
        </a>
        をご覧ください。
      </p>

      <h3>免責事項</h3>

      <p>
        当ブログは、掲載内容によって生じた損害に対する一切の責任を負いません。
        各コンテンツでは、できる限り正確な情報提供を心がけておりますが、正確性や安全性を保証するものではありません。
        また、リンク先の他サイトで提供される情報・サービスについても、責任を負いかねますのでご了承ください。
      </p>

      <h3>著作権</h3>

      <p>
        当ブログに掲載されている文章・画像の著作権は、運営者に帰属しています。
        法的に認められている引用の範囲を超えて、無断で転載することを禁止します。
      </p>

      <h4>【motoblog】 及び 【Book eternalアプリ】</h4>
    </div>
  );
}

export default PrivacyPolicy;
